<template>
  <div class="formation">
    <liste_formation/>
    
  </div>
</template>

<script>
// @ is an alias to /src

import liste_formation from '../components/l_formation.vue'

export default {
  name: 'Formationliste',
  components: {
    liste_formation,
  }
}
</script>
